import {examUrl} from "../http/baseUrl";
import {post,put} from "../http/http";

//软件企业测评提交
export const confirmBase=(params)=> {
  return put(`${examUrl}/rjqyee`,params, {hideLoading: true});
}


//软件企业测评列表
export const getReportInfo=(params)=> {
  return post(`${examUrl}/rjqyee/list?pageSize=1&pageNum=1`,params, {hideLoading: false, hideError: true});
}

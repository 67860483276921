import {examUrl} from "../http/baseUrl";
import {get,post,del,put} from "../http/http";

//科小测评提交
export const confirmBase=(params)=> {
  return put(`${examUrl}/kxee`,params, {hideLoading: true});
}

//科小测评暂存
export const confirmStageBase=(params)=> {
  return post(`${examUrl}/kxee`,params, {hideLoading: true});
}

//科小测评列表
export const getReportInfo=(params)=> {
  return post(`${examUrl}/kxee/list?pageSize=1&pageNum=1`,params, {hideLoading: false, hideError: true});
}
